// ** Third Party Components
import styled from "styled-components";
// ** Custom Components
import Logo from "components/global/Logo/Logo";

// #####################################################

export const StyledFooterLogo = styled(Logo).attrs(({ mode, ...props }) => ({
	mode,
	...props,
}))`
	display: block;
	height: 30px;
	width: 115px;
`;

// #####################################################
