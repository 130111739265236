// ** Third Party Components
import styled from "styled-components";

// ** Styles Import
import { Container } from "styles/components/Container.styled";
import { breakpoints } from "styles/grid";

// #####################################################

export const StyledInnerContainer = styled(Container)`
	// Na max-width jest -1 pixel, żeby nie robiły się bugi na breakpointach np. 600/1050px, więcej w tasku DZ-2263.
	@media (max-width: ${breakpoints.phone - 1}px) {
		padding: 2rem;
	}

	@media (min-width: ${breakpoints.tablet}px) {
		padding: 0.75rem 1rem;
	}

	@media (min-width: ${breakpoints.desktop}px) {
		padding: 1.25rem 1rem;
	}
`;

// #####################################################
