import LogoWhite from "./components/LogoWhite";
import LogoBlue from "./components/LogoBlue";

const Logo = ({ mode, ...props }) => {
	if (mode === "white") {
		return <LogoWhite width="130" height="34" {...props} />;
	} else {
		return <LogoBlue width="130" height="34" {...props} />;
	}
};

export default Logo;
